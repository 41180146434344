import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Parallax } from "swiper/modules";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import { RiCircleFill } from "react-icons/ri";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/parallax";

import "./Carousel.css";

const carouselData = [
  {
    title: "Global Context",
    description:
      "Disease registries are essential for collecting comprehensive data that drives research, policy, and clinical decisions globally. They track disease patterns, identify risk factors, and improve patient outcomes, particularly for emerging and rare diseases. Registries enable global collaboration, equipping healthcare systems to address public health challenges through data-driven approaches.",
  },
  {
    title: "India Context",
    description:
      " In India, many diseases, especially emerging or less common ones, lack comprehensive data, hindering effective public health responses. Our registry aims to bridge these gaps by providing a centralized platform for data collection, enhancing epidemiological insights, and supporting evidence-based public health strategies.",
  },
  {
    title: "The Value of Real-World Registries",
    description:
      "Real-world registries provide crucial data on treatment effectiveness, safety, and cost in everyday practice. This supports clinical decisions, regulatory submissions, and market access, benefiting both healthcare providers and the pharmaceutical industry.",
  },
];

const SwiperCarousel = () => {
  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };
  return (
    <div
      className="carousel-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Swiper
        modules={[Navigation, Autoplay, Parallax]}
        spaceBetween={20}
        slidesPerView={1}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        parallax={true}
        speed={1000}
        onSwiper={setSwiper}
      >
        {carouselData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              <div className="slide-content-top">
                <div className="slide-content-top-left">
                  <RiCircleFill className="circle-icon" />
                </div>
                <div>
                  <h2
                    className="slide-title"
                    data-swiper-parallax="-100"
                    data-swiper-parallax-opacity="0"
                    data-swiper-parallax-duration="600"
                  >
                    {slide.title}:
                  </h2>
                </div>
              </div>
              <div
                className="slide-content-bottom"
                data-swiper-parallax="-200"
                data-swiper-parallax-duration="800"
              >
                <p className="slide-description">{slide.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="navigation-buttons">
        <button className="swiper-button-prev nav-button">
          <BiSolidLeftArrow
            className="icon"
            style={{ transform: "scale(0.8)" }}
          />
        </button>
        <button className="swiper-button-next nav-button">
          <BiSolidRightArrow
            className="icon"
            style={{ transform: "scale(0.8)" }}
          />
        </button>
      </div>
    </div>
  );
};

export default SwiperCarousel;
