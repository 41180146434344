import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import "./SearchBar.css";
import { useSearch } from "../../context/SearchContext";
import { FaWandMagicSparkles } from "react-icons/fa6";

const SearchBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const { searchInput, setSearchInput } = useSearch();
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (searchInput.trim()) {
      // Simulated backend response
      // const data = {
      //   response: 'hello new page'
      // };

      // Navigate to response page with the response data
      navigate("/response", { state: { query: searchInput } });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={isScrolled ? "search-bar scrolled-search-bar" : "search-bar"}
    >
      <input
        type="text"
        placeholder="Ask anything about coGuide"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <button onClick={handleSearch}>
        <FaWandMagicSparkles size={10} />
        {window.innerWidth > 756 && (
          <span className="search-text">AI Search</span>
        )}
      </button>
    </div>
  );
};

export default SearchBar;
