import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchResponsePageSearchBar.css";
import { useSearch } from "../../context/SearchContext";
import { FaWandMagicSparkles } from "react-icons/fa6";


const SearchResponsePageSearchBar = ({searchInput,setSearchInput,handlePromptSubmit}) => {

  return (
    <div className={"response-search-bar"}>
      <input
        type="text"
        placeholder="Ask anything about coGuide"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <button onClick={handlePromptSubmit}>
        <FaWandMagicSparkles size={10} />
        {window.innerWidth > 756 &&
          <span className="response-search-text">Send</span>
        }
      </button>
    </div>
  );
};

export default SearchResponsePageSearchBar;
