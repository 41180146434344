import { Box } from "@mui/material";
import registriesList from "./sampleRegistriesData";
import RegistriesCard from "./RegistriesCard";
import "./Registries.css";
import { useEffect, useRef, useState } from "react";
import RegistryDetail from "./RegistryDetail";
import Carousel from "./Carousel";
import TestimonialCarousel from "./TestimonialCarousel";
import RegistryCard from "./RegistryCard";
import { getRegistryData } from "../../api/apiController";

const Registries = () => {
  const [action, setAction] = useState("");
  const [selectedRegistry, setSelectedRegistry] = useState({});
  const [shouldScroll, setShouldScroll] = useState(false);
  const listOfRegistriesRef = useRef(null);
  const numberOfVideos = 1;
  const videoToBePlayed = Math.floor(Math.random() * numberOfVideos) + 1;
  const [registryData,setRegistryData] = useState([]);

  useEffect(() => {
    if (action === "" && shouldScroll && listOfRegistriesRef.current) {
      setTimeout(() => {
        listOfRegistriesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        setShouldScroll(false);
      }, 0);
    }
  }, [action, shouldScroll]);

  useEffect(() => {
    const fetchRegistryData = async () => {
      try {
        const response = await getRegistryData();
        setRegistryData(response.data);
      } catch (error) {
        console.error("Error fetching registry data:", error);
      }
    };
  
    fetchRegistryData();
  }, []);

  const onAction = (value, data = {}) => {
    switch (value) {
      case "registrydetail": {
        setSelectedRegistry(data);
        setAction(value);
        break;
      }
      default: {
        setAction("");
        setShouldScroll(true);
      }
    }
  };
  return (
    <>
      {action === "registrydetail" && (
        <RegistryDetail data={selectedRegistry} onClose={() => onAction("")} onAction={onAction}/>
      )}
      {
        action === "" && (
          <div className="container">
            <div className="registry-container">
              <div className="what-is-registry">
                <div className="what-is-registry-left">
                  <h2>What Is</h2>
                  <h1>REGISTRY?</h1>
                  <p>
                    The registry is an information system designed for the
                    collection, storage, and management of data on persons with
                    a Disease. Registries play a critical role in disease
                    surveillance, which tells us where we are in the efforts to
                    reduce the disease burden. Surveillance data may also serve
                    as a foundation for disease research and are used to plan
                    and evaluate disease prevention and control interventions.
                  </p>
                </div>
                <div className="what-is-registry-right">
                  <img src="/images/registry_image.jpeg" alt="banner" />
                </div>
              </div>
              <div className="benefits-of-registry">
                <div className="benefits-of-registry-left">
                  <h2>Benefits</h2>
                  <h3>Of Registry:</h3>
                </div>
                <div className="benefits-of-registry-mid">
                  <ul>
                    <li>Drawing real time deep clinical insights</li>
                    <li>Extended patient engagement</li>
                    <li>Research publications</li>
                    <li>Enhanced patient retention</li>
                    <li>Additional revenue stream</li>
                  </ul>
                </div>
                <div className="benefits-of-registry-right">
                  <ul>
                    <li>Enhanced care models</li>
                    <li>Data informed clinical decision making</li>
                    <li>Research/ evidence driven brand building</li>
                    <li>Industry engagement</li>
                    <li>Research driven network building</li>
                  </ul>
                </div>
              </div>
              <div className="needs-of-registry">
                <div className="needs-of-registry-left">
                  <h2>Needs</h2>
                  <h3>Of A Registry?</h3>
                </div>
                <div className="needs-of-registry-right">
                  <Carousel />
                </div>
              </div>
              <div className="vision-mission">
                <div className="vision-mission-left">
                  <video width="auto" height="100%" autoPlay loop muted>
                    <source
                      src={`/videos/registry_vid.mp4`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="vision-mission-right">
                  <div className="vision-mission-right-top">
                    <h3>Vision:</h3>
                    <p>
                      To transform global evidence informed clinical practice
                      landscape through establishment of disease/ population
                      based registries addressing major global public health
                      problems
                    </p>
                  </div>
                  <div className="vision-mission-right-bottom">
                    <h3>Mission:</h3>
                    <p>
                      To establish 1000+ registries spreading across the globe
                      by 2030 with the aim of influencing at least 100+
                      treatment guidelines and 10+ major policy changes.
                    </p>
                    <p>
                      To emerge as the Global leader in evidence based registry
                      landscape with footprints and highest market share in
                      across all the 6 WHO regions
                    </p>
                  </div>
                </div>
              </div>
              <div className="list-of-registries" ref={listOfRegistriesRef}>
                <div className="list-of-registries-title">
                  <h2>List of registries:</h2>
                </div>
                <div className="list-of-registries-cards-container">
                  {registryData &&
                    registryData.map((item, index) => {
                      return <RegistryCard data={item} onAction={onAction} />;
                    })}
                </div>
              </div>
              {/* <div className="testimonials">
                <TestimonialCarousel />
              </div> */}
            </div>
          </div>
        )
        // <div className="registries-container">
        // 	<div className="registries-cards-container">
        // 		{registriesList && registriesList.length !== 0 ? (
        // 			<Box
        // 				sx={{
        // 					display: 'flex',
        // 					flexWrap: 'wrap',
        // 					justifyContent: 'space-evenly',
        // 					gap: '2rem',
        // 				}}
        // 			>
        // 				{registriesList &&
        // 					registriesList.map((item, index) => {
        // 						return (
        // 							<Box>
        // 								<RegistriesCard data={item} onAction={onAction} />
        // 							</Box>
        // 						);
        // 					})}
        // 			</Box>
        // 		) : (
        // 			<div container style={{ marginTop: '100px' }}>
        // 				No Records Found.
        // 			</div>
        // 		)}
        // 	</div>
        // </div>
      }
    </>
  );
};

export default Registries;
