import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import './Evidence.css';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

export default function ProductCard(props) {
  const { data } = props;
  // console.log(data);
  return (
    <>
      <Card className="product-card">
        <div className="banner-container">
          <CardMedia
            className="banner"
            component="img"
            alt="category-name"
            height="150"
            image={
              data && data
                ? data.image
                : 'https://altum.com/wp-content/uploads/2023/05/Altum_May_BlogAssets_2023-1536x886.jpg'
            }
          />
          <EmojiObjectsIcon className="evidence-card-icon" style={{ fontSize: '40px' }} />
        </div>
        <CardContent
          sx={{
            height: '45%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography
            className="evidence-title"
            variant="body1"
            sx={{
              height: '78%',
            }}
          >
            {data.title}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: '#005AD2',
            }}
          >
            <a
              className="read-more"
              href={data.link}
              target="_blank"
              rel="noreferrer"
            >
              Read More
            </a>
          </Button>
        </CardContent>
      </Card>
    </>
  );
}
