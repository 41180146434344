import { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchInput, setSearchInput] = useState("");
  const [chatMessages, setChatMessages] = useState([]); // For chat messages

  const clearChatMessages = () => setChatMessages([]); // Clear on close

  return (
    <SearchContext.Provider
      value={{ searchInput, setSearchInput, chatMessages, setChatMessages, clearChatMessages }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
