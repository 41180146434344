import React from "react";

const RegistryCard = (props) => {
  return (
    <div className="registry-card-container">
      <div className="registry-card-container-top">
        <img
          src={props.data.thumbnail}
          alt={props.data.title || "Sample Registry Image"}
        />
      </div>
      <div className="registry-card">
        <div className="registry-card-left">
          <div className="registry-card-left-box">
            {props.data.icon && (
              <img
                src={`images/registry_logos/${props.data.icon}.png`}
                // alt={props.data.title || "Sample Registry Icon"}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
          </div>
        </div>
        <div className="registry-card-right">
          <div className="registry-card-right-top">
            {props.data.title_small}
          </div>
          <div className="registry-card-right-mid">
            {props.data.objective_small}
          </div>
          <div
            className="registry-card-right-bottom"
            onClick={() => props.onAction("registrydetail", props?.data)}
          >
            Find out more
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistryCard;
