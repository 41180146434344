import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Box, Stack, Typography } from "@mui/material";

const testPartners = {
    partners: [
        {
            name: "Blossom Hospital",
            logo: "https://via.placeholder.com/400x300?text=Nature+Image",
            partner_type: "gold",
            address: [
                {
                    address_type: "headquarters",
                    country: "India",
                    country_code: "IN",
                    state: "Maharashtra",
                    state_code: "MH",
                    city: "Mumbai",
                    address_text: "123 Blossom St",
                    pincode: "400001",
                },
            ],
            contact_details: {
                name: "Dr. John Doe",
                contact_number: "+91 9876543210",
                emailid: "contact@blossomhospital.com",
            },
        },
        {
            name: "Lotus Clinic",
            logo: "https://via.placeholder.com/400x300?text=Hospital+Image",
            partner_type: "silver",
            address: [
                {
                    address_type: "branch",
                    country: "India",
                    country_code: "IN",
                    state: "Delhi",
                    state_code: "DL",
                    city: "New Delhi",
                    address_text: "45 Lotus Rd",
                    pincode: "110001",
                },
            ],
            contact_details: {
                name: "Dr. Jane Smith",
                contact_number: "+91 9876543211",
                emailid: "info@lotusclinic.com",
            },
        },
        {
            name: "Sunshine Health Center",
            logo: "",
            partner_type: "gold",
            address: [
                {
                    address_type: "headquarters",
                    country: "India",
                    country_code: "IN",
                    state: "Karnataka",
                    state_code: "KA",
                    city: "Bangalore",
                    address_text: "78 Sunshine Ave",
                    pincode: "560001",
                },
            ],
            contact_details: {
                name: "Dr. Alice Brown",
                contact_number: "+91 9876543212",
                emailid: "contact@sunshinehealth.com",
            },
        },
        // Add more entries as needed for testing
        {
            name: "Blossom Hospital",
            logo: "/images/blossom_hospital.jpeg",
            partner_type: "gold",
            address: [
                {
                    address_type: "headquarters",
                    country: "India",
                    country_code: "IN",
                    state: "Maharashtra",
                    state_code: "MH",
                    city: "Mumbai",
                    address_text: "123 Blossom St",
                    pincode: "400001",
                },
            ],
            contact_details: {
                name: "Dr. John Doe",
                contact_number: "+91 9876543210",
                emailid: "contact@blossomhospital.com",
            },
        },
    ],
};

// Now use `testPartners` instead of `data` when passing to the `PartnerCarousel` for testing.


const PartnerCarousel = ({ data, isMobile }) => {
    return (
        <Stack direction="row" spacing={2}>
            {/* Left side box with record count and partner count */}
            <Box
                sx={{
                    bgcolor: "#006EDA",
                    borderRadius: "30px",
                    padding: isMobile ? "1rem" : "2rem",
                    color: "white",
                    flex: 0.35, // 35% of the width
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                }}
            >
                <Box>
                    <Typography variant={isMobile ? "h6" : "h4"}>
                        Record count: {data.record_count}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant={isMobile ? "h6" : "h4"}>
                        Partner count: {data.partner_count}
                    </Typography>
                </Box>
            </Box>

            {/* Right side with the Swiper carousel */}
            <Box
                sx={{
                    flex: 0.65, // 65% of the width
                    borderRadius: "30px",
                    overflow: "hidden", // To ensure the content stays within the rounded corners
                }}
            >
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    loop={true} // Enables looping to the first slide after the last one
                    spaceBetween={20}
                    slidesPerView={1}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {data.partners.map((partner, index) => (
                        <SwiperSlide key={index}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "white",
                                    borderRadius: "30px",
                                    padding: "1rem",
                                    height: "100%", // Ensures it fills the height of the parent container
                                    textAlign: "center",
                                }}
                            >
                                {partner.logo == "linktologo" || partner.logo == "" ?
                                    <img
                                        src={`images/partner_images/partner_placeholder.jpeg`}
                                        style={{
                                            width: "100%",
                                            maxHeight: "250px",
                                            maxWidth: '300px',
                                            objectFit: "contain",
                                            borderRadius: "15px",
                                        }}
                                    />
                                    :
                                    <img
                                        src={`images/partner_images/${partner.logo}.jpeg`}
                                        style={{
                                            width: "100%",
                                            maxHeight: "250px",
                                            maxWidth: '300px',
                                            objectFit: "contain",
                                            borderRadius: "15px",
                                        }}
                                    />
                                }
                                {partner.name &&

                                    <Typography
                                        variant={isMobile ? "h6" : "h4"}
                                        sx={{ marginTop: "1rem" }}
                                    >
                                        {partner.name}{" "}
                                        <span
                                            style={{
                                                color: partner.partner_type === "gold" ? "#FFD700" : "#C0C0C0",
                                                fontSize: isMobile ? "0.8rem" : "1rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {partner.partner_type} Partner
                                        </span>
                                    </Typography>
                                }
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Stack>
    );
};

export default PartnerCarousel;
