import { Box } from '@mui/material';

const Clients = ({ imgUrl }) => {
  return (
    <Box
      sx={{
        width: '200px',
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <img
        className="clients-img"
        src={imgUrl}
        alt="Client"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

export default Clients;
