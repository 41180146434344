import React, { useEffect, useRef, useState } from 'react';
import './Chatbot.css';
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { IoChatboxEllipses } from "react-icons/io5";
import { getChatResponse } from '../../api/apiController';


function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I help you today?' },
    { sender: 'user', text: 'Can you tell me about your services?' },
    { sender: 'bot', text: 'Sure! We offer a variety of services to help businesses grow, including web development, SEO, and digital marketing.' },
    { sender: 'user', text: 'That sounds great! How can I get started?' },
    { sender: 'bot', text: 'You can get started by filling out our contact form or giving us a call. We’re here to help!' },
    { sender: 'user', text: 'That sounds great! How can I get started?' },
    { sender: 'bot', text: 'You can get started by filling out our contact form or giving us a call. We’re here to help!' },
    { sender: 'user', text: 'That sounds great! How can I get started?' },
    { sender: 'bot', text: 'You can get started by filling out our contact form or giving us a call. We’re here to help!' },
    { sender: 'user', text: 'That sounds great! How can I get started?' },
    { sender: 'bot', text: 'You can get started by filling out our contact form or giving us a call. We’re here to help!' },
  ]);

  const [inputValue, setInputValue] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const messagesEndRef = useRef(null);

  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
    
    // Add user's message to the chat
    const newMessage = { sender: 'user', text: inputValue };
    setMessages([...messages, newMessage]);
    setInputValue('');
    setIsProcessing(true);

    try {
      // Call the backend API
      const response = await getChatResponse(newMessage.text);

      const data = await response.json();

      // Add chatbot's response to the chat
      const botMessage = { sender: 'bot', text: data.reply };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  // Scroll to bottom whenever messages update or chat opens
  useEffect(() => {
    if (isOpen && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isOpen]);

  return (
    <div className="chatbot-container">
      <div className="chatbot-chat-icon" onClick={() => setIsOpen(!isOpen)}>
      <IoChatboxEllipses />
      </div>

      {isOpen && (
        <div className="chatbot-chat-window">
          <div className="chatbot-chat-header">
            <h3>Chatbot</h3>
            <button onClick={() => setIsOpen(false)}><IoMdClose />
            </button>
          </div>
          <div className="chatbot-chat-messages chatbot-custom-scrollbar">
            {messages.map((msg, index) => (
              <div key={index} className={`chatbot-message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
            <div ref={messagesEndRef} /> 
          </div>
          <div className="chatbot-chat-input">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !isProcessing && handleSendMessage()}
              disabled={isProcessing}
              placeholder="Enter your message..."
            />
            <button onClick={handleSendMessage} disabled={isProcessing} className="chatbot-send-button">
              <IoSend />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chatbot;
